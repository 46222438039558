<template>
  <DetailedPageContainer>
    <div class="detailed__images" v-if="images.length > 0">
      <r-row :wrap="false" class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 4 }"
               v-for="(img, index) in images"
               :key="index">
          <r-image :url="img.replace('extra', 'medium')"/>
        </r-col>
      </r-row>
    </div>

    <div class="detailed__title">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 12 }">
          <div class="detailed__category mozzarella metropolis--text" v-if="categoryTitle">
            {{ categoryTitle }}
          </div>

          <h2 class="ricotta harakiri--text">
            {{ currentItem.title }}
          </h2>
        </r-col>
      </r-row>
    </div>

    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <div class="details" v-if="duration">
          <r-icon icon="clock" fill="rocky"/>
          <span class="zamorano">
            {{ duration }}
          </span>
        </div>

        <div class="details" v-if="address">
          <r-icon icon="geopoint" fill="rocky"/>
          <span class="zamorano">
            {{ address }}
          </span>
        </div>

        <div class="details" v-if="phone">
          <r-icon icon="telephone" fill="rocky"/>
          <span class="zamorano">
            {{ phone }}
          </span>
        </div>

        <div class="details" v-if="website">
          <r-icon icon="link" fill="rocky"/>
          <span class="zamorano">
            {{ website }}
          </span>
        </div>

        <div class="details" v-if="email">
          <r-icon icon="mail" fill="rocky"/>
          <span class="zamorano">
            {{ email }}
          </span>
        </div>

        <QrCode v-if="website" :value="website" />
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <MoreText v-if="description" :text="description"/>

        <div class="detailed__agency" v-if="agency && type !== 'org'">
          <AgencyAndGuideCard :name="agency.title"
                              :image="agency.image ? agency.image.replace('extra', 'small') : ''"
                              :type="agency.type"
                              @click.native="goToAgency(agency.id)"
                              size="small"
          />
        </div>
      </r-col>
    </r-row>

    <div class="detailed__additional-info" v-if="additions">
      <AdditionalInfo :data="additions"/>
    </div>

    <div class="detailed__more-items">
      <MoreItemsList :title="$t('more.more_excursions')"
                     :category="categoryId"
                     type="excursions"
                     :data-set="GET_EXCURSIONS"/>
    </div>
  </DetailedPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import DetailedPageContainer from "@/components/Containers/DetailedPageContainer";
import AdditionalInfo from "@/components/DetailedViewParts/AdditionalInfo/AdditionalInfo";
import MoreText from "@/components/DetailedViewParts/MoreText/MoreText";
import QrCode from "@/components/DetailedViewParts/QrCode/QrCode";
import MoreItemsList from "@/components/DetailedViewParts/MoreItemsList/MoreItemsList";
import AgencyAndGuideCard from "@/components/DetailedViewParts/AgencyAndGuideCard/AgencyAndGuideCard";

export default {
  name: "DetailedExcursionView",

  components: {
    AgencyAndGuideCard,
    DetailedPageContainer,
    MoreItemsList,
    QrCode,
    AdditionalInfo,
    MoreText,
  },

  computed: {
    ...mapGetters(['GET_EXCURSIONS',]),

    currentItem() {
      return this.GET_EXCURSIONS.filter(item => item.id == this.$route.params.id)[0];
    },

    categoryId() {
      return this.currentItem?.category?.id;
    },

    categoryTitle() {
      return this.currentItem?.category?.title;
    },

    images() {
      return this.currentItem?.images;
    },

    duration() {
      if (this.currentItem?.duration_int) {
        return this.$duration(this.currentItem?.duration_int);
      }

      return null;
    },

    distance() {
      if (this.currentItem?.distance_int) {
        return this.$distance(this.currentItem?.distance_int);
      }

      return null;
    },

    description() {
      return this.currentItem?.description.replace(/<[^>]*>?/gm, '');
    },

    type() {
      return this.currentItem?.agency?.type;
    },

    address() {
      return this.currentItem?.agency?.address;
    },

    phone() {
      return this.currentItem?.agency?.phone;
    },

    website() {
      return this.currentItem?.agency?.website;
    },

    email() {
      return this.currentItem?.agency?.email;
    },

    agency() {
      return this.currentItem?.agency;
    },

    additions() {
      const arr = [];

      if (this.distance) {
        arr.push({
          id: 'distance',
          key: this.$t('additions.distance'),
          value: this.distance,
        });
      }

      if (this.currentItem?.level?.title) {
        arr.push({
          id: 'level',
          key: this.$t('additions.difficulty'),
          value: this.currentItem?.level?.title
        });
      }

      if (this.currentItem?.season?.title) {
        arr.push({
          id: 'season',
          key: this.$t('additions.seasonality'),
          value: this.currentItem?.season?.title
        });
      }

      if (this.currentItem?.age_restriction?.title) {
        arr.push({
          id: 'age',
          key: this.$t('additions.age_restriction'),
          value: `${this.currentItem?.age_restriction?.title}+`
        });
      }

      return arr;
    }
  },

  methods: {
    goToAgency(id) {
      this.$router.push({ path: `/travel-agencies-and-guides/${id}` });
    }
  }
}
</script>
