<template>
  <div class="agency-guide-card" :class="{ 'agency-guide-card--small': size }">
    <div class="agency-guide-card__image">
      <img :src="image" :alt="name" />
    </div>

    <div class="agency-guide-card__content">
      <div class="agency-guide-card__type metropolis--text mozzarella">
        {{ currentType }}
      </div>

      <div class="agency-guide-card__name harakiri--text ricotta" :class="{ 'bryndza': size }">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgencyAndGuideCard",

  props: {
    name: {
      type: String,
      default: () => '',
    },
    image: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    size: {
      type: String,
      default: () => '',
    }
  },

  computed: {
    currentType() {
      switch(this.type) {
        case 'guide':
          return this.$t('views.agencies_and_guides.guide');
        case 'travel':
          return this.$t('views.agencies_and_guides.travel');
        default:
          return '';
      }
    }
  }
}
</script>

<style lang="scss">
.agency-guide-card {
  display: flex;
  align-items: center;

  &__image {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {

  }

  &__name {

  }

  &__type {
    margin-bottom: 4px;
  }

  &--small {

    .agency-guide-card__image {
      width: 72px;
      height: 72px;

      margin-right: 12px;
    }

    .agency-guide-card__content {
      flex-direction: column-reverse;
    }
  }
}

</style>
